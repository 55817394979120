import { asset1, asset2 } from './assets'

const assets = [
  { src: asset1, x: 0, y: 0, itemId: 110 },
  { src: asset2, x: 241.1137, y: 125.398, itemId: 111, blendMode: 'no-blend' },
]

const data = {
  assets: assets,
}

export default data
