import React, { useState, useEffect, createContext } from 'react'
import { Outlet } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import ReactGA from 'react-ga4'
import { getSeasonsDropdownOptions } from 'api/orders'
import { Button, Icon, ImageLoad, Select } from 'components'
import PlayerOrders from './player-orders'
import TeamOrders from './team-orders'
import classes from './orders.module.scss'
import { generateTeamPdf } from 'helpers/pdf'

type ThemeType = 'light' | 'dark'
export const ThemeContext = createContext<ThemeType>('dark')

type OrderState =
  | 'list-view'
  | 'detail-view'
  | 'edit'
  | 'edit-close'
  | 'warn-empty-layer'
  | 'save'
  | 'submit'

export default function Orders() {
  const isMobile = useMediaQuery({ maxWidth: 1023 })
  const userData = sessionStorage.getItem('user')
  const user: any = userData ? JSON.parse(userData) : null
  const userTheme =
    sessionStorage.getItem('theme') ||
    (user?.sportId === 'football-ncaa' ? 'light' : 'dark')
  const [theme, setTheme] = useState<ThemeType>(userTheme as ThemeType)
  const [animateIcon, setAnimateIcon] = useState(false)

  const [orderState, setOrderState] = useState<OrderState>('list-view')
  const [loadingState, setLoadingState] = useState(0)
  const { seasons } = getSeasonsDropdownOptions()
  const filters: any = sessionStorage.getItem('filters')
  const [selectedSeason, setSelectedSeason] = useState(
    filters ? filters : user?.sportId === 'football-ncaa' ? '2026' : '2025',
  )
  const [selectedOrder, setSelectedOrder] = useState({})

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Orders',
    })

    setTimeout(() => {
      setLoadingState(1)
    }, 800)

    setTimeout(() => {
      setLoadingState(2)
    }, 1600)
  }, [])

  useEffect(() => {
    if (theme === 'light') {
      document.body.classList.remove('dark')
      document.body.classList.add('light')
    } else {
      document.body.classList.remove('light')
      document.body.classList.add('dark')
    }

    return () => {
      document.body.classList.remove('light')
      document.body.classList.remove('dark')
    }
  }, [theme])

  if (loadingState <= 1) {
    return (
      <div className={classes.loadingContainer}>
        <div
          className={`${classes.welcomeMessage} ${
            loadingState === 1 ? classes.slideUp : ''
          }`}>
          <div className={classes.greet}>Welcome</div>
          <div className={classes.user}>{user.name}</div>
        </div>

        {loadingState === 1 && (
          <div className={classes.logoContainer}>
            <Icon name="logo" />
          </div>
        )}
      </div>
    )
  }

  const selectSeason = (option: any) => {
    setSelectedSeason(option.value)
    sessionStorage.setItem('filters', option.value)
  }

  const selectOrder = (order: any) => {
    setSelectedOrder(order)
  }

  const toggleTheme = () => {
    setAnimateIcon(true)
    setTimeout(() => setAnimateIcon(false), 300)

    if (theme === 'dark') {
      setTheme('light')
    } else {
      setTheme('dark')
    }

    sessionStorage.setItem('theme', theme === 'dark' ? 'light' : 'dark')
  }

  return (
    <ThemeContext.Provider value={theme}>
      <div
        className={`${classes.container} ${
          orderState === 'edit' ? classes.edit : ''
        }  ${orderState === 'edit-close' ? classes.editClose : ''}`}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.logoContainer}>
              <Icon name="logoSmall" />
            </div>
            <div className={classes.userThemeContainer}>
              <div
                className={`${classes.theme} ${
                  animateIcon ? classes.animate : ''
                }`}
                onClick={toggleTheme}>
                {theme === 'light' ? (
                  <div className={classes.rotateClock}>
                    <Icon name="sun" />
                  </div>
                ) : (
                  <div className={classes.rotateAnticlock}>
                    <Icon name="moon" />
                  </div>
                )}
              </div>
              <div className={classes.seperator}></div>
              <div className={classes.userContainer}>
                <div className={classes.userInfo}>
                  <div className={classes.userName}>{user.name}</div>
                  {user.teamName && (
                    <div className={classes.team}>{user.teamName}</div>
                  )}
                </div>
                <div className={classes.userIcon}>
                  <ImageLoad src={user.profileLogo} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.ordersMainSection}>
          <div className={classes.ordersSection}>
            <div className={classes.secondHeaderContainer}>
              <div className={`${classes.header} ${classes.secondHeader}`}>
                <div className={classes.title}>
                  Pick a style to review and customize
                </div>
                <div className={classes.seasonContainer}>
                  <div className={classes.seasonLabel}>Season</div>
                  <div className={classes.seasonSelector}>
                    <Select
                      theme={theme}
                      size="large"
                      align={isMobile ? 'left' : 'right'}
                      options={seasons}
                      value={selectedSeason}
                      onSelect={selectSeason}
                    />
                  </div>
                  {user.isTeam && !isMobile && (
                    <div className={classes.teamExport}>
                      <Button
                        theme={theme}
                        variant="outline"
                        onClick={() =>
                          generateTeamPdf(user.name, selectedSeason)
                        }>
                        Export
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.ordersContainer} id="orders-container">
              <div className={classes.ordersListContainer}>
                {user.isTeam ? (
                  <TeamOrders
                    user={user}
                    season={selectedSeason}
                    onSelect={selectOrder}
                  />
                ) : (
                  <PlayerOrders
                    user={user}
                    season={selectedSeason}
                    onSelect={selectOrder}
                  />
                )}
              </div>
            </div>
          </div>
          <Outlet context={[orderState, setOrderState, selectedOrder]} />
        </div>
      </div>
    </ThemeContext.Provider>
  )
}
