import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  swoosh,
  layer3d,
  layerShadow,
} from './assets'

const assets = [
  { src: asset1, x: 0, y: 0, itemId: 101 },
  { src: asset2, x: 0, y: 0, globalComposition: 'multiply' },
  { src: asset3, x: 310.3749, y: 718.8027 },
  { src: asset4, x: 320.8581, y: 719.5413, itemId: 104 },
  { src: asset5, x: 0.0195, y: 79.5171, itemId: 102 },
  { src: asset6, x: 413.4951, y: 78.9255, itemId: 106 },
  { src: asset7, x: 10.9275, y: 78.9255, itemId: 105 },
  { src: asset8, x: 387.6531, y: 1536.9741, itemId: 107 },
  { src: asset9, x: 757.5576, y: 1448.1003 },
  { src: asset10, x: 437.9004, y: 1433.8506, itemId: 109 },
  { src: asset11, x: 456.7338, y: 1580.0103, itemId: 110 },
  {
    src: asset12,
    x: 664.1553,
    y: 1687.6545,
    itemId: 111,
    blendMode: 'no-blend',
  },
  { src: swoosh, x: 553.9908, y: 1183.3128, itemId: 103 },
]

const data = {
  assets: assets,
  '3dLayer': [
    { src: layer3d, x: -241.2942, y: -195.8937, globalComposition: 'multiply' },
  ],
  shadow: [{ src: layerShadow, x: 61.1616, y: 24.5037 }],
}

export default data
